import React from 'react';
import styles from './MaintenanceOverlay.module.css';
import { IconToolsOff } from '@tabler/icons-react';

const MaintenanceOverlay = () => {
  return (
    <div className={styles.overlay}>
      <div className={styles.container}>
        <div className={styles.content}>
          <div className={styles.iconContainer}>
            <IconToolsOff className={styles.icon} />
          </div>
          
          <div className={styles.messageContainer}>
            <div className={styles.title}>
              Temporary Service Interruption
            </div>
            <div className={styles.message}>
              Our AI Music Video Generator is currently experiencing technical difficulties and is temporarily unavailable. Our team is working diligently to resolve this issue.
            </div>
            <div className={styles.subMessage}>
              Please check back later. We apologize for any inconvenience.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MaintenanceOverlay;