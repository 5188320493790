import { useState } from 'react';
import { NavLink, Burger, Group, Avatar, Button, Title } from '@mantine/core';
import { IconLayoutDashboard, IconVideo, IconCreditCard, IconHelp, IconLogout, IconSparkles, IconMusic } from '@tabler/icons-react';
import { Link } from 'react-router-dom';
import styles from './Sidebar.module.css';
import { useAuth } from '../contexts/AuthContext';

const Sidebar = () => {
  const [opened, setOpened] = useState(false);
  const { currentUser, signOutUser } = useAuth();

  return (
    <div className={styles.wrapper}>
      <Burger
        opened={opened}
        onClick={() => setOpened((o) => !o)}
        size="sm"
        className={styles.burger}
      />
      <div className={`${styles.sidebar} ${opened ? styles.open : ''}`}>
        <div className={styles.section}>
          <Group className={styles.logo} position="apart">
            <Group style={{marginTop: '5vh'}}>
              {/* <IconMenu2 size={28} stroke={2.5} /> */}
              <IconSparkles color='black' size="1.4rem" stroke={2.5} /> 
              <Title size="xl" weight={900}>
                AI Video
              </Title>
            </Group>
            {/* <Burger
              opened={opened}
              onClick={() => setOpened((o) => !o)}
              size="sm"
              className={styles.burger}
            /> */}
          </Group>
          <NavLink
            component={Link}
            to="/dashboard"
            label="Dashboard"
            leftSection={<IconLayoutDashboard size="1rem" stroke={2.5} />}
            // icon={<IconLayoutDashboard size={18} />}
            className={styles.link}
          />
          <NavLink
            component={Link}
            to="/generate-new-video"
            label="Generate New Video"
            leftSection={<IconVideo size="1rem" stroke={2.5} />}
            // icon={<IconVideo size={18} />}
            className={styles.link}
          />
          <NavLink
            component={Link}
            to="/ai-music-video"
            label="New Music Video"
            leftSection={<IconMusic size="1rem" stroke={2.5} />}
            // icon={<IconVideo size={18} />}
            className={styles.link}
          />
          <NavLink
            component={Link}
            to="/billing"
            label="Billing"
            // icon={<IconCreditCard size={18} />}
            leftSection={<IconCreditCard size="1rem" stroke={2.5} />}
            className={styles.link}
          />
          <NavLink
            component="a"
            href="mailto:support@aivideo.to"
            target='_blank'
            label="Support"
            leftSection={<IconHelp size="1rem" stroke={2.5} />}
            // icon={<IconHelp size={18} />}
            className={styles.link}
          />


          <Button className={styles.button} onClick={signOutUser} leftSection={<IconLogout size="1rem" stroke={2.5} />}>Sign out</Button>

        </div>
        <div style={{width: '100%', padding: "10px 20px", marginBottom: '10vh', position: 'absolute', bottom: 10}}>
          <Group style={{marginBottom: '2vh'}}><Avatar name={currentUser?.displayName} color="initials" /> <strong>{currentUser?.displayName}</strong></Group>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
