import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
// import App from './App.jsx';
// import Login from './components/Login';
// import Signup from './components/Signup';
// import Dashboard from './components/Dashboard';
// import ResetPassword from './components/ResetPassword';
// import PrivateRoute from './components/PrivateRoute';
// import PricingPage from './components/PricingPage.jsx';
// import Success from './components/Success.jsx';

import App from './App.jsx';
import Login from './components/Login';
import Signup from './components/Signup';
import Dashboard from './components/Dashboard';
import ResetPassword from './components/ResetPassword';
import PrivateRoute from './components/PrivateRoute';
import PricingPage from './components/PricingPage.jsx';
import Success from './components/Success.jsx';
import Pricing from './components/Pricing.jsx';
import ReelDetail from './components/ReelDetail.jsx';
import SubscribedRoute from './components/SubscribedRoute.jsx';
import Billing from './components/Billing.jsx';
import Terms from './components/Terms.jsx';
import Privacy from './components/Privacy.jsx';
import TiktokLanding from './components/TiktokLanding.jsx';


import { AuthProvider } from './contexts/AuthContext';
import { createBrowserRouter, RouterProvider, createRoutesFromChildren,
  matchRoutes,
  useLocation,
  useNavigationType, } from 'react-router-dom';
import { MantineProvider } from '@mantine/core';

import LoadingScreen from './components/LoadingScreen.jsx';

// import Pricing from './components/Pricing.jsx';
// import FetchReels from './components/FetchReels.jsx';
// import ReelDetail from './components/ReelDetail.jsx';
// import SubscribedRoute from './components/SubscribedRoute.jsx';
// import Billing from './components/Billing.jsx';
// import Terms from './components/Terms.jsx';
// import Privacy from './components/Privacy.jsx';
// import FacelessYoutube from './blogs/FacelessYoutube.jsx';

// Lazy load components
// const App = React.lazy(() => import('./App.jsx'));
// const Login = React.lazy(() => import('./components/Login'));
// const Signup = React.lazy(() => import('./components/Signup'));
// const Dashboard = React.lazy(() => import('./components/Dashboard'));
// const ResetPassword = React.lazy(() => import('./components/ResetPassword'));
// const PrivateRoute = React.lazy(() => import('./components/PrivateRoute'));
// const PricingPage = React.lazy(() => import('./components/PricingPage.jsx'));
// const Success = React.lazy(() => import('./components/Success.jsx'));
// const Pricing = React.lazy(() => import('./components/Pricing.jsx'));
// const FetchReels = React.lazy(() => import('./components/FetchReels.jsx'));
// const ReelDetail = React.lazy(() => import('./components/ReelDetail.jsx'));
// const SubscribedRoute = React.lazy(() => import('./components/SubscribedRoute.jsx'));
// const Billing = React.lazy(() => import('./components/Billing.jsx'));
// const Terms = React.lazy(() => import('./components/Terms.jsx'));
// const Privacy = React.lazy(() => import('./components/Privacy.jsx'));
// const TiktokLanding = React.lazy(() => import('./components/TiktokLanding.jsx'));


import './index.css';
import '@mantine/core/styles.css';


import * as Sentry from "@sentry/react";
import MusicVideo from './components/MusicVideo.jsx';
import MusicDetail from './components/MusicDetail.jsx';
import FetchContent from './components/FetchReels.jsx';
import AIMusicLanding from './AIMusicLanding.jsx';

Sentry.init({
  dsn: "https://256786f9d93adc8c631385b1bf4f6612@o4507773800611840.ingest.us.sentry.io/4507773801857024",
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  // tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(
  createBrowserRouter,
);


const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <App />,
  },
  {
    path: "/ai-music-video-generator",
    element: <AIMusicLanding />,
  },
  {
    path: "/ai-tiktok-video-generator",
    element: <TiktokLanding />,
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/signup",
    element: <Signup />,
  },
  {
    path: "/reset-password",
    element: <ResetPassword />,
  },
  {
    path: "/generate-new-video",
    element: <SubscribedRoute><Dashboard /></SubscribedRoute>,
  },
  {
    path: "/dashboard",
    element: <SubscribedRoute><FetchContent /></SubscribedRoute>,
  },
  {
    path: "/billing",
    element: <SubscribedRoute><Billing /></SubscribedRoute>,
  },
  {
    path: "/ai-video/:id",
    element: <SubscribedRoute><ReelDetail /></SubscribedRoute>,
  },
  {
    path: "/music-video/:id",
    element: <SubscribedRoute><MusicDetail /></SubscribedRoute>,
  },
  {
    path: "/subscribe",
    element: <PrivateRoute><PricingPage /></PrivateRoute>,
  },
    {
    path: "/success",
    element: <Success />,
  },
  {
    path: "/pricing",
    element: <Pricing />,
  },
  {
    path: "/terms",
    element: <Terms />,
  },
  {
    path: "/privacy",
    element: <Privacy />,
  },
  {
    path: "/ai-music-video",
    element: <SubscribedRoute><MusicVideo /></SubscribedRoute>,
  },
  // {
  //   path: "/blog/how-to-run-faceless-youtube-shorts-channel-with-ai-video-earn-10k-per-month",
  //   element: <IframeComponent src="" />
  // }
]);

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <MantineProvider>
      <AuthProvider>
        <RouterProvider router={router} />
      </AuthProvider>
    </MantineProvider>
  </React.StrictMode>,
);
